import { contractNoteDTO } from "./contractNotes.model";

export default function ContractNote(props: contractNoteDTO) {
  return (
    <div className="container text-center">
      <h1>Contract Note</h1>
      <div className="row">
        <div className="col">
          <span className="label">Trade Date: </span>
          {props.tradeDate.toString()}
        </div>

        <div className="col">
          <span className="label">Contract Note No:</span>
          {props.contractNoteNo}
          {props.contractNoteNo}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <span className="label">Instruction </span> {props.instruction}
        </div>
      </div>
      <div className="row">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Quantity</th>
              <th>Security</th>
              <th>Stock code</th>
              <th>Price</th>
              <th>Consideration</th>
            </tr>
          </thead>
          <tbody>
            <tr key={props.contractNoteNo}>
              <td>{props.quantity}</td>
              <td>{props.shareName}</td>
              <td>{props.stockCode}</td>
              <td>{props.unitPrice}</td>
              <td>{props.consideration}</td>
            </tr>
            <tr>
              <td></td>
              <td>Commission</td>
              <td></td>
              <td></td>
              <td>{props.commission}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>HL Fund & Share Account</th>
              <th>Settlement Date</th>
              <th>{props.settlementDate.toString()}</th>
              <th></th>
              <th>{props.totalContractValue}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
