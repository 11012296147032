import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { landingUrl } from "./endpoints";


export default function LandingPage() {
  const [msg, setMsg] = useState<string>();
  useEffect(() => {

    axios
    .get(landingUrl)
    .then((response: AxiosResponse<string>) => 
    {
      
      setMsg(response.data);
    });
  }, []);
  const dte = new Date();
  
  
  return (
    <>
      Landing Page component loaded :  {msg} on {dte.toISOString()} at 
      {dte.toLocaleTimeString()}
       
    </>
  );
}
