
import { useEffect, useState } from "react";
import { shareListProps, sharesLandingDTO } from "./shares.model";

export default function ShareListing(){
    const date = new Date(Date.now());
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    //console.log(date);
    const [sharesHeld, setShares] = useState<sharesLandingDTO>({})
    useEffect(() => {
      const timerId = setTimeout(() => {
      setShares({
        shares: [
          { 
            shareName : 'Just eat',
            cost: 500,
            numberHeld : 50,
            id : 1,
          },
          {
            shareName : 'Microsoft',
            cost: 15500,
            numberHeld : 50,
            id : 2,
          }
        ]
      })
    }, 1000);
    return () => clearTimeout(timerId);
  }); 
    return(
        
        <div>
            <h1 className="text-center">List of shares held as at {date.toLocaleDateString()}</h1>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Share</th>
                        <th>Held</th>
                        <th>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {sharesHeld.shares?.map(share => 
                        <tr key={share.id}>
                            <td>{share.id}</td>
                            <td>{share.shareName}</td>
                            <td>{share.numberHeld}</td>
                            <td>{share.cost}</td>
                        </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}
