import { useParams } from "react-router-dom"

export default function EditContractNote(){
    const {id} : any = useParams();
    return(
        <>
            <h3>Edit Contract Note</h3>
            Id is {id}
        </>
    )
}