import GenericList from "../utils/GenericList";
import Loading from "../utils/Loading";
import {
  contractNoteDTO,
  contractNotesLandingDTO,
} from "./contractNotes.model";
import css from "./ContractNoteList.module.css";
import ContractNote from "./ContractNote";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { contractNoteList } from "../endpoints";

export default function ContractNoteList() {
  const [contractNotes, setContractNotes] = useState<contractNoteDTO[]>();
  useEffect(() => {
    axios
      .get(contractNoteList)
      .then((response: AxiosResponse<contractNoteDTO[]>) => {
        setContractNotes(response.data);
        console.log(response.data);
      });
  }, []);

  return (
    <>
      <h3>Contract Notes List</h3>
      <Link className="btn btn-primary" to="/contractnotes/create">
        Create
      </Link>
      <GenericList list={contractNotes}>
        <div className={css.div}>
          {contractNotes?.map((contract) => (
            <ContractNote
              {...contract}
              key={contract.contractNoteNo?.toString()}
            />
          ))}
        </div>
      </GenericList>
    </>
  );
}
/* export interface contractNoteListProps{
    contractNotes? : contractNoteDTO[];
} */
