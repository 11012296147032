import LandingPage from "./LandingPage";
import ContractNoteList from "./contractNotes/ContractNoteList";
import CreateContractNote from "./contractNotes/CreateContractNote";
import EditContractNote from "./contractNotes/EditContractNote";
import ShareListing from "./shares/ShareListing";
import RedirectLandingPage from "./utils/RedirectLandingPage";

const routes = [
    {path: "/contractnotes", component: ContractNoteList},
    {path: "/contractnotes/create", component: CreateContractNote},
    {path: "/contractnotes/edit/:id", component: EditContractNote},

    {path: "/sharesheld", component: ShareListing},

    {path: "/", component: LandingPage},

    //catch all route
    {path: "*", component: RedirectLandingPage}
];

export default routes;