import { Form, Formik, FormikHelpers } from "formik";
import { contractNoteCreationDTO } from "./contractNotes.model"
import TextField from "../forms/TextField";
import Button from "../utils/Button";
import { Link } from "react-router-dom";
import Select, { selectItem } from "../forms/Select";
import * as Yup from "yup";
import DateField from "../forms/DateField";

const instructions:selectItem[] = [
    {value:"", option:"Please select type of instruction"},
    {value:"bought", option:"Purchase"},
    {value:"sold", option:"Sale"}, 
    {value:"other", option:"Other"}]

export default function ContractNoteForm(props: contractNoteFormProps){
    return(
        <Formik 
            initialValues={props.model} 
            onSubmit={props.onSubmit} 
            validationSchema={Yup.object({
                // instruction: Yup.string().required("Instruction required"),
                contractNoteNo: Yup.string().required("Contract Note Number required"),
              })}
              >
                
                {(formikProps) => (
                    <Form>
                      <TextField field="contractNoteNo" displayName="Contract Note No:"></TextField>
                      <DateField field="contractNoteDate" displayName="Contract Note Date" ></DateField>
                      <DateField field="tradeDate" displayName="Trade Date" ></DateField>
                      <DateField field="settlementDate" displayName="Settlemnt Date"></DateField>
                      <Select label="Instruction" name="instruction" list={instructions}></Select>
                      <TextField field="quantity" displayName="No. of Shares"></TextField>
                      <TextField field="stockCode" displayName="Stock Code"></TextField>
                      <TextField field="shareName"displayName="Share Name"></TextField>
                      <TextField field="classOfShare" displayName="Class of Share"></TextField>
                      <TextField field="price" displayName="Unit Price"></TextField>
                      <TextField field="currency" displayName="Currency"></TextField>
                      <TextField field="pricePence" displayName="Price in Pence"></TextField>
                      <TextField field="exchangeRate" displayName="Exchange Rate"></TextField>
                      <TextField field="foreignExchangeCharge" displayName="Foreign Exchange Charge"></TextField>
                      <TextField field="consideration" displayName="Consideration"></TextField>
                      <TextField field="commission" displayName="Commission"></TextField>
                      <TextField field="transferStamp" displayName="Transfer Stamp Charge"></TextField>
                      <TextField field="totalContractValue" displayName="Total Contract Value"></TextField>
                      <Button disabled={formikProps.isSubmitting} type="submit">Save Contract Note</Button>
                      <Link to="/contractnotes" className="btn btn-secondary">
                        Cancel
                      </Link>
                  </Form>
                )}
        </Formik>
    )
}
interface contractNoteFormProps{
    model: contractNoteCreationDTO;
    onSubmit(values: contractNoteCreationDTO, action: FormikHelpers<contractNoteCreationDTO>) :void;
}

