import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function DropDownMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <>
      <div className={`dropdown nav-item ${isOpen ? "show" : ""}`}>
        <button
          className="btn btn-sm btn-outline-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={toggleDropdown}
        >
          Contract Notes
        </button>
        <div
          className={`dropdown-menu ${isOpen ? "show" : ""}`}
          aria-labelledby="dropdownMenuButton"
        >
          <Link className="dropdown-item" to="/contractnotes">
            List
          </Link>
          <Link className="dropdown-item" to="/contractnotes/create">
            Create
          </Link>
        </div>
      </div>
    </>
  );
}
