import { Field, ErrorMessage } from "formik";

export default function Select(props: selectProps) {
  return (
    <>
      <label htmlFor={props.name}>{props.label}</label>
      <Field as='select' id={props.name} name={props.name} className="form-select mb-3" >
{
    props.list.map(option =>{
        return(
            <option key={option.value} value={option.value}>
                {option.option}
            </option>
        )
    })
}
      </Field>
      <ErrorMessage className="text-danger mb-3" name={props.name} component="div"></ErrorMessage>
    </>
  );
}
interface selectProps {
  label: string;
  name: string;
  list:selectItem[];
}
export interface selectItem{
    value:string;
    option:string
}
