import { useNavigate } from "react-router-dom";

import {
  contractNoteCreationDTO,
  contractNoteDTO,
} from "./contractNotes.model";
import axios from "axios";
import { contractNoteList, urlContractNotesAdd } from "../endpoints";

import ContractNoteForm from "./ContractNoteForm";

export default function CreateContractNote() {
  async function create(contractNote: contractNoteCreationDTO) {
    try {
      console.log(contractNote);

      await axios
        .post(urlContractNotesAdd, contractNote)
        .then(() => navigate('/'));
    } catch (error) {
      console.error(error);
    }
  }

  const navigate = useNavigate();

  const currentTime = new Date();

  return (
    <>
      <h3 className="text-center">Create Contract Note</h3>
      <hr />
      <ContractNoteForm
        model={{
          contractNoteNo: "12345",
          contractNoteDate: currentTime,
          tradeDate: currentTime,
          settlementDate: currentTime,
          instruction: "bought",
          quantity: 0,
          stockCode: "",
          shareName: "",
          classOfShare : "",
          price: 0,
          pricePence : 0,
          currency : "GBP",
          exchangeRate: 1,
          foreignExchangeCharge: 0,
          transferStamp : 0,
          consideration: 0,
          commission: 11.95,
          totalContractValue: 0,
        }}
        onSubmit={async (values) => {
          await create(values);
        }}
      />
    </>
  );
}
